import { GetProfileResponse } from '@basisboard/basis-common/lib/api'
import { bootIntercom } from './intercom'
import { bootSentry } from './sentry'

export * from './eventBus'
export * from './experiments'
export * from './intercom'

export const initServices = (profile: GetProfileResponse) => {
  bootIntercom(profile)
  bootSentry(profile)
}
