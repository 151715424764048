import {
  COMPANY_ID,
  GetMessagesResponse,
  GetProjectCompanyEmailAddress,
  MESSAGE_ID,
  PatchMessageRequestBody,
  PatchMessageResponse,
  PostMessageLinkRequestBody,
  PostMessageRequestBody,
  PROJECT_ID,
  SearchEmailDetailsResponseBody,
} from '@basisboard/basis-common/lib/api'
import { del, get, patch, post } from '../../api'
import { EmailEntity, EmailEntityId } from './types'

export const getMessage = (messageId: MESSAGE_ID) =>
  get<GetMessagesResponse>(`/messages/${messageId}`)

export const getMessagesForEntity = (
  entity: EmailEntity,
  entityId: EmailEntityId,
  params?: { offset?: number; limit?: number },
) =>
  get<SearchEmailDetailsResponseBody>(`/${entity}/${entityId}/messages`, { params }).then(
    response => ({
      messages: response.data.messages,
      total: response.data.total,
    }),
  )

export const deleteMessage = (messageId: MESSAGE_ID) => del(`/messages/${messageId}`)

export const undeleteMessage = (messageId: MESSAGE_ID) =>
  patch<PatchMessageResponse>(`/messages/${messageId}`, {
    deletedAt: null,
  } as PatchMessageRequestBody)

export const markAsRead = (messageId: MESSAGE_ID) =>
  patch(`/messages/${messageId}`, { isRead: true })

export const markAsUnread = (messageId: MESSAGE_ID) =>
  patch(`/messages/${messageId}`, { isRead: false })

export const relinkMessage = (messageId: MESSAGE_ID, body: PostMessageLinkRequestBody) =>
  post(`/messages/${messageId}/link`, body)

export const createMessage = (projectId: PROJECT_ID, body: PostMessageRequestBody) =>
  post(`/projects/${projectId}/messages`, body)

export const replyMessage = (
  projectId: PROJECT_ID,
  messageId: MESSAGE_ID,
  body: PostMessageRequestBody,
) => post(`/projects/${projectId}/messages/${messageId}/reply`, body)

export const getLinkEmailAddress = (projectId: PROJECT_ID, companyId: COMPANY_ID) =>
  get<GetProjectCompanyEmailAddress>(
    `/projects/${projectId}/companies/${companyId}/email-address`,
  ).then(response => response.data.emailAddress)
