import env from '@beam-australia/react-env'
import { createBrowserHistory } from 'history'

declare global {
  interface Window {
    REACT_APP_API_URL?: string
    REACT_APP_MICROSOFT_CLIENT_ID?: string
    REACT_APP_SENTRY_DSN?: string
    REACT_APP_INTERCOM_APP_ID?: string
    REACT_APP_GOOGLE_MAP_API_KEY?: string
    REACT_APP_SENTRY_ORG_SLUG?: string
    REACT_APP_NYLAS_CLIENT_ID?: string
  }
}

export const config = {
  API_URL: window.REACT_APP_API_URL || env('API_URL') || process.env.REACT_APP_API_URL,
  MICROSOFT_CLIENT_ID:
    window.REACT_APP_MICROSOFT_CLIENT_ID ||
    env('MICROSOFT_CLIENT_ID') ||
    process.env.REACT_APP_MICROSOFT_CLIENT_ID,
  SENTRY_DSN: window.REACT_APP_SENTRY_DSN || env('SENTRY_DSN') || process.env.REACT_APP_SENTRY_DSN,
  INTERCOM_APP_ID:
    window.REACT_APP_INTERCOM_APP_ID ||
    env('INTERCOM_APP_ID') ||
    process.env.REACT_APP_INTERCOM_APP_ID,
  GOOGLE_MAP_API_KEY:
    window.REACT_APP_GOOGLE_MAP_API_KEY ||
    env('GOOGLE_MAP_API_KEY') ||
    process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  SENTRY_ORG_SLUG:
    window.REACT_APP_SENTRY_ORG_SLUG ||
    env('SENTRY_ORG_SLUG') ||
    process.env.REACT_APP_SENTRY_ORG_SLUG,
  NYLAS_CLIENT_ID:
    window.REACT_APP_NYLAS_CLIENT_ID ||
    env('NYLAS_CLIENT_ID') ||
    process.env.REACT_APP_NYLAS_CLIENT_ID,
}

export const history = createBrowserHistory()

export const isDev = process.env.NODE_ENV === 'development'
